import React from 'react';
import Button from '../Button';
import TextLoader from '../TextLoader';
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Form from '../Form';
import { UPDATE_PROJECT } from './mutations';
import { Select, Input, InputNumber, Divider } from 'antd';
import { TextArea } from '../Input';
import { GET_CLIENTS } from '../../../admin/queries';

const { Option } = Select;

const GET_TAXONOMY = gql`
  query getTaxonomy($name: String!) {
    taxonomy(name: $name) {
      _id
      name
      description
      terms {
        _id
        name
        order
      }
    }
  }
`;

const UpdateProjectForm = ({ project, onComplete, refetchQueries = [] }) => {
  const [updateProject] = useMutation(UPDATE_PROJECT, { refetchQueries });
  const { loading: loadingClients, data: clientQueryData } = useQuery(GET_CLIENTS);
  const { loading, data } = useQuery(GET_TAXONOMY, {
    variables: {
      name: 'Sectors',
    },
  });
  const clients = loadingClients ? [] : clientQueryData?.admin?.clients || [];
  const sectors = data?.taxonomy?.terms || [];
  // const [isSubmitting, setIsSubmitting] = useState(false);

  if (loading || loadingClients) {
    return <TextLoader text="Fetching sectors and clients" />;
  }
  return (
    <Formik
      initialValues={{
        ...project,
        jobName: project.quickbooks?.jobName,
        clientId: project.client._id,
      }}
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const {
          projectCode,
          name,
          description,
          sectors,
          accountingCode,
          accountingCodeSuffix,
          budget,
          jobName,
          clientId,
        } = values;
        console.log({ clientId });

        setTimeout(async () => {
          await updateProject({
            variables: {
              projectId: project._id,
              projectCode,
              name,
              description,
              sectors,
              accountingCode,
              accountingCodeSuffix,
              clientId,
              budget,
              jobName,
            },
          });
          onComplete();
          setSubmitting(false);
        }, 1000);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Item
            label="Project Code"
            validateStatus={errors.projectCode && touched.projectCode && 'error'}
            help={
              (errors.projectCode && touched.projectCode && errors.projectCode) ||
              'Provide an optional project code'
            }
            hasFeedback
          >
            <Input
              name="projectCode"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.projectCode}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item
            label="Project Name"
            validateStatus={errors.name && touched.name && 'error'}
            help={errors.name && touched.name && errors.name}
            hasFeedback
            required
          >
            <Input
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            validateStatus={errors.description && touched.description && 'error'}
            help={errors.description && touched.description && errors.description}
            hasFeedback
          >
            <TextArea
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item label="Client">
            <Select
              allowClear
              loading={loadingClients}
              placeholder="Select an existing client"
              defaultValue={project.client._id}
              onChange={value => {
                setFieldValue('clientId', value);
              }}
              onBlur={handleBlur}
            >
              {clients.map(client => (
                <Option key={client._id} value={client._id}>
                  {client.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Sectors"
            validateStatus={errors.sectors && touched.sectors && 'error'}
            help={
              (errors.sectors && touched.sectors && errors.sectors) || 'Select at least one sector'
            }
            hasFeedback
          >
            <Select
              mode="multiple"
              placeholder="Select sectors"
              defaultValue={project.sectors || []}
              onChange={selectedSectors => {
                setFieldValue('sectors', selectedSectors);
              }}
              disabled={isSubmitting}
            >
              {sectors.map(sector => (
                <Option key={sector.name}>{sector.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Divider>Accounting</Divider>
          <Form.Item
            label="Budget"
            validateStatus={errors.budget && touched.budget && 'error'}
            help={(errors.budget && touched.budget && errors.budget) || 'Specify project budget'}
            hasFeedback
          >
            <InputNumber
              className="w-full"
              name="budget"
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              onChange={value => {
                setFieldValue('budget', value);
              }}
              onBlur={handleBlur}
              defaultValue={values.budget}
              disabled={isSubmitting}
            />
          </Form.Item>
          <div className="flex flex-wrap sm:flex-no-wrap gap-2">
            <Form.Item
              className="w-full sm:w-1/2"
              label="Accounting Code"
              validateStatus={errors.accountingCode && touched.accountingCode && 'error'}
              help={
                (errors.accountingCode && touched.accountingCode && errors.accountingCode) ||
                'Optional accounting code'
              }
              hasFeedback
            >
              <Input
                name="accountingCode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.accountingCode}
                disabled={isSubmitting}
              />
            </Form.Item>
            <Form.Item
              className="w-full sm:w-1/2"
              label="Accounting Code Suffix"
              validateStatus={
                errors.accountingCodeSuffix && touched.accountingCodeSuffix && 'error'
              }
              help={
                (errors.accountingCodeSuffix &&
                  touched.accountingCodeSuffix &&
                  errors.accountingCodeSuffix) ||
                'Optional accounting code suffix'
              }
              hasFeedback
            >
              <Input
                name="accountingCodeSuffix"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.accountingCodeSuffix}
                disabled={isSubmitting}
              />
            </Form.Item>
          </div>
          <Divider>Quickbooks</Divider>
          <Form.Item
            className="w-full"
            label="Quickbooks Job Name"
            validateStatus={errors.jobName && touched.jobName && 'error'}
            help={
              (errors.jobName && touched.jobName && errors.jobName) ||
              'Optional quickbooks job name'
            }
            hasFeedback
          >
            <Input
              name="jobName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.jobName}
              disabled={isSubmitting}
            />
          </Form.Item>
          <div className="flex justify-end">
            <Button loading={isSubmitting} type="primary" htmlType="submit">
              Update
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateProjectForm;
